import React from "react";
import '../styles/style.css';

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="container">
        
        </div>

        <div className="footer-bottom">
          <p className="copyright">
            &copy; 2024 All rights reserved. Made with ❤ by John Tech.
          </p>
        </div>
      
    </footer>
  );
};

export default Footer;
